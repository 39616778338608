/* REGULAR EXPRESSIONS */

// Regular expression for Names - allows alphanumeric , - _ . ( ) [ ] : and spaces
export const validNameRegex = /^[a-zA-Z0-9,_.()[\]: -]+$/;

// Regular expression for Token Name, inspired by RFC 1123 Label, but allows lowercase letters only
export const ingestionTokenNameRegex = /^[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;

// Regular expression for validating UUID v4
export const uuidV4Regex =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
// Regular expression for AWS Account ID
// https://docs.aws.amazon.com/organizations/latest/APIReference/API_Account.html
export const awsAccountIdRegex = /^\d{12}$/;
// Regular expression for AWS Supported Regions
export const awsSupportedRegionsRegex =
  /^((us-east-1)|(us-east-2)|(us-west-1)|(us-west-2)|(ap-south-1)|(ap-northeast-3)|(ap-northeast-2)|(ap-southeast-1)|(ap-southeast-2)|(ap-northeast-1)|(ca-central-1)|(eu-central-1)|(eu-west-1)|(eu-west-2)|(eu-west-3)|(eu-north-1)|(sa-east-1))$/;
// Regular expression for GCP Project ID
// https://cloud.google.com/resource-manager/docs/creating-managing-projects#before_you_begin
export const gcpProjectIdRegex = /^[a-z][a-z0-9-]{4,28}[a-z0-9]$/;
// Okta
// Client ID - ref: https://support.okta.com/help/s/question/0D54z00007D97BpCAJ/allowed-characters-and-length-for-clientsecret?language=en_US
export const oktaClientIdRegex = /^[a-zA-Z0-9\-_.,!*'()]+$/;
// Domain - ref: https://developer.okta.com/docs/guides/find-your-domain/
export const oktaDomainRegex =
  /^https?:\/\/[a-zA-Z0-9-]+\.okta(-preview|-emea)?\.com$/;

/* CHAR LIMITS */
// Jira limits ref: https://community.atlassian.com/t5/Jira-questions/Character-limit-of-Description-field-in-Jira-Software-cloud/qaq-p/2655913#M936122
// AWS limits ref: https://docs.aws.amazon.com/organizations/latest/APIReference/API_Account.html
// GCP limits ref: https://cloud.google.com/resource-manager/docs/creating-managing-projects#before_you_begin
// Okta limits ref: https://support.okta.com/help/s/question/0D54z00007D97BpCAJ/allowed-characters-and-length-for-clientsecret?language=en_US
export const charLimits = {
  Name: 100,
  Badge: 20,
  Title: 255,
  ActionDetails: 500,
  Description: 4000,
  LongDescription: 64000,
  JiraSingleLineTextField: 255,
  JiraMultiLineTextField: 32767,
  RFC1123Label: 63,
  AWSAccountId: 12,
  GCPProjectIdMax: 30,
  GCPProjectIdMin: 6,
  OktaClientId: 100,
  OktaClientSecret: 90,
} as const;

/* ALLOWED HTML TAGS FOR MARKDOWN */
export const allowedHtmlTagsForMarkdown = [
  'p',
  'strong',
  'em',
  'code',
  'a',
  'br',
  'li',
  'ul',
  'ol',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'blockquote',
  'pre',
  'hr',
  'table',
  'th',
  'tr',
  'thead',
  'tbody',
  'td',
];

/* ERROR MESSAGES */
export const invalidNameInputErrorMessage =
  'Name can only include letters, numbers, spaces, and the symbols: , - _ . : ( ) [ ]';
export const fieldIsRequiredErrorMessage = (
  field: string,
  plural?: boolean,
) => {
  return `${field} ${plural ? 'are' : 'is'} required`;
};
export const charLimitExceededErrorMessage = (field: string, limit: number) => {
  return `${field} must be at most ${limit}`;
};
export const charLimitLessThanMinErrorMessage = (
  field: string,
  limit: number,
) => {
  return `${field} must be at least ${limit} characters`;
};
